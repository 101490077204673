
  import { computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
        default: '弹窗',
      },
      width: {
        type: Number,
        required: false,
        default: 1000,
      },
    },
    emits: ['update:show'],
    setup(props, context) {
      const visible = computed(() => {
        console.log(props.show)
        return props.show
      })

      const onCancel = () => {
        context.emit('update:show', false)
      }

      return {
        visible,
        props,
        onCancel,
      }
    },
  })


  import { defineComponent } from 'vue'
  import MyMenu from './components/MyMenu.vue'
  import MyAvatar from './components/MyAvatar.vue'
  export default defineComponent({
    components: {
      MyMenu,
      MyAvatar,
    },
  })

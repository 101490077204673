import axios from '@/common/utils/axios'
import { AxiosRequestConfig } from 'axios'

export const requests = (options: AxiosRequestConfig) => {
  return axios({ method: 'post', ...options } as AxiosRequestConfig)
}

export const API = {
  Auth: {
    Login: 'auth/login',
    Me: 'auth/me',
    Logout: 'auth/logout',
    AliyunSts: 'auth/aliyun_sts',
    UpdateMe: 'auth/update_me',
  },
  project_appliers: {
    List: 'project_appliers/list',
    Show: 'project_appliers/show',
  },
  project_accepts: {
    Store: 'project_accepts/store',
    My_list: 'project_accepts/my_list',
    Show: 'project_accepts/show',
    Audit_list: 'project_accepts/audit_list',
    Update: 'project_accepts/update',
  },
  project_graph_applies: {
    List: 'project_graph_applies/list',
    Show: 'project_graph_applies/show',
  },
  project_graph_accepts: {
    Store: 'project_graph_accepts/store',
    MyList: 'project_graph_accepts/my_list',
    Show: 'project_graph_accepts/show',
    AuditList: 'project_graph_accepts/audit_list',
    Update: 'project_graph_accepts/update',
  },
}

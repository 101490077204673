import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createVNode(_component_a_pagination, {
      current: _ctx.page.current_page,
      "page-size": _ctx.page.per_page,
      total: _ctx.page.total,
      "show-size-changer": "",
      "show-total": (total) => `共 ${total} 条`,
      "page-size-options": ['10', '20', '50', '100', '200', '500', '10000'],
      onChange: _ctx.onChange,
      onShowSizeChange: _ctx.onShowSizeChange,
      class: "pagination"
    }, {
      buildOptionText: _withCtx((props) => [
        (props.value !== '10000')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.value) + " 条/页", 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "全部"))
      ]),
      _: 1
    }, 8, ["current", "page-size", "total", "show-total", "onChange", "onShowSizeChange"])
  ]))
}
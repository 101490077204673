
  import { defineComponent, ref, watch, watchEffect } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import MyIcon from '@/common/components/MyIcon/index.vue'
  export default defineComponent({
    components: { MyIcon },
    setup() {
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const menusList = ref([])
      const currentkey = ref([route.path])
      const permissions: any = [
        {
          children: [
            {
              children: [],
              icon: 'AlignTextCenterOne',
              id: 2,
              name: '发起验收',
              url: '/admin/project_appliers',
            },
            {
              children: [],
              icon: 'DocSuccess',
              id: 3,
              name: '已提交',
              url: '/admin/project_accepts',
            },
            {
              children: [],
              icon: 'AlignTextLeftOne',
              id: 4,
              name: '被驳回',
              url: '/admin/project_appliers_rejected',
            },
          ],
          icon: 'AlignTextCenterOne',
          id: 1,
          name: '效果图管理',
          url: '',
        },
        {
          icon: 'AlignTextCenterOne',
          id: 5,
          name: '打图管理',
          url: '',
          children: [
            {
              children: [],
              icon: 'AlignTextCenterOne',
              id: 6,
              name: '发起验收',
              url: '/admin/map/appliers_list',
            },
            {
              children: [],
              icon: 'DocSuccess',
              id: 7,
              name: '已提交',
              url: '/admin/map/appliers_submittedlist',
            },
            {
              children: [],
              icon: 'AlignTextLeftOne',
              id: 8,
              name: '被驳回',
              url: '/admin/map/appliers_turndown',
            },
          ],
        },
      ]
      const onClick = (e: any) => {
        currentkey.value = [e.key]
        router.push(e.key)
      }
      watch(
        () => route.path,
        (res) => {
          currentkey.value = [res]
        }
      )
      return {
        currentkey,
        menusList,
        onClick,
        permissions,
      }
    },
  })

import { defineComponent } from 'vue'
import { string } from 'vue-types'

export const MyCreateButton = defineComponent({
  props: {
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <a-button type="primary" disabled={props.disabled}>
        <my-icon icon="Add"></my-icon>
        {props.title || '添加'}
      </a-button>
    )
  },
})
export const MyQueryButton = defineComponent({
  props: {
    title: String,
    icon: {
      type: String,
      default: 'Search',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <a-button type="primary" disabled={props.disabled}>
        <my-icon icon={props.icon}></my-icon>
        {props.title || '查询'}
      </a-button>
    )
  },
})

export const MyEditButton = defineComponent({
  props: {
    title: String,
    size: String,
    type: {
      default: 'primary',
    },
    icon: {
      default: 'Edit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <a-button disabled={props.disabled} type={props.type}>
        <my-icon icon={props.icon} size={props.size}></my-icon>
        {props.title || '编辑'}
      </a-button>
    )
  },
})

export const MyDeleteButton = defineComponent({
  props: {
    popTitle: String,
    title: String,
    type: {
      default: 'danger',
    },
    icon: {
      default: 'Delete',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => (
      <a-popconfirm
        title={props.popTitle}
        ok-text="是"
        cancel-text="否"
        disabled={props.disabled}
      >
        <a-button danger disabled={props.disabled}>
          <my-icon icon={props.icon}></my-icon>
          {props.title || '删除'}
        </a-button>
      </a-popconfirm>
    )
  },
})

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/common/layouts/layout.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    redirect: 'admin/project_appliers',
    component: Layout,
    children: [
      {
        path: 'admin/project_appliers',
        name: 'project_appliers_index',
        component: () => import('@/views/project_appliers/list.vue'),
      },
      {
        path: 'admin/project_show',
        name: 'project_appliers_show',
        component: () => import('@/views/project_appliers/show.vue'),
      },
      {
        path: 'admin/project_appliers_rejected',
        name: 'project_appliers_rejected',
        component: () => import('@/views/project_appliers/rejectedlist.vue'),
      },
      {
        path: 'admin/project_appliers_update',
        name: 'project_appliers_update',
        component: () => import('@/views/project_appliers/update.vue'),
      },
      {
        path: 'admin/project_accepts',
        name: 'project_accepts_index',
        component: () => import('@/views/project_accepts/list.vue'),
      },
      {
        path: 'admin/project_accepts_show',
        name: 'project_accepts_show',
        component: () => import('@/views/project_accepts/show.vue'),
      },
      {
        path: 'admin/map/appliers_list',
        name: 'project_map_appliers_list',
        component: () => import('@/views/map/list.vue'),
      },
      {
        path: 'admin/map/appliers_submittedlist',
        name: 'project_map_appliers_submittedlist',
        component: () => import('@/views/map/submittedlist.vue'),
      },
      {
        path: 'admin/map/appliers_create',
        name: 'project_map_appliers_create',
        component: () => import('@/views/map/create.vue'),
      },
      {
        path: 'admin/map/appliers_update',
        name: 'project_map_appliers_update',
        component: () => import('@/views/map/update.vue'),
      },
      {
        path: 'admin/map/appliers_show',
        name: 'project_map_appliers_show',
        component: () => import('@/views/map/show.vue'),
      },
      {
        path: 'admin/map/appliers_turndown',
        name: 'project_map_appliers_turndown',
        component: () => import('@/views/map/turndownlist.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_icon = _resolveComponent("my-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    theme: "dark",
    style: {"line-height":"64px"},
    mode: "horizontal",
    selectedKeys: _ctx.currentkey,
    "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentkey) = $event)),
    onSelect: _ctx.onClick
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (i) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: i.url
        }, [
          (i.children.length == 0)
            ? (_openBlock(), _createBlock(_component_a_menu_item, {
                key: i.url
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_my_icon, {
                    icon: i.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(i.name), 1)
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_a_sub_menu, {
                key: i.url
              }, {
                title: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createVNode(_component_my_icon, {
                      icon: i.icon
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", null, _toDisplayString(i.name), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.children, (k) => {
                    return (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: k.url
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_my_icon, {
                          icon: k.icon
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(k.name), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["selectedKeys", "onSelect"]))
}
<template>
  <div class="loadingContainer" v-show="count > 0">
    <a-spin size="large" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState({
        count: (state) => state.loading.count,
      }),
    },
  }
</script>

<template>
  <icon-park
    :type="icon"
    theme="outline"
    style="position:relative;top:2px;"
    :fill="fill"
  />
</template>
<script>
  import { IconPark } from '@icon-park/vue-next/es/all'
  export default {
    name: 'MyIcon',
    components: {
      IconPark,
    },
    props: {
      icon: {
        type: String,
        required: true,
      },
      fill: {
        type: String,
      },
    },
  }
</script>

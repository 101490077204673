/**
 * useToken(key)
 * @param key Token的键
 */
export function useToken(key: string) {
  /**
   * 获取Token
   */
  function getToken() {
    return localStorage.getItem(key)
  }

  /**
   * 设置Token
   * @param accessToken Token值
   */
  function setToken(accessToken: string) {
    return localStorage.setItem(key, accessToken)
  }

  /**
   * 删除Token
   */
  function removeToken() {
    return localStorage.removeItem(key)
  }

  return {
    getToken,
    setToken,
    removeToken,
  }
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupAntdv } from './common/setup/setupAntdv'
import '@/assets/styles/global.less'
import { setupCommon } from './common/setup/setupCommon'
import '@/common/utils/style.less'
const app = createApp(App)
  .use(store)
  .use(router)

setupAntdv(app)
setupCommon(app)

app.mount('#app')

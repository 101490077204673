
  import { computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    emits: ['change'],
    setup(props, context) {
      const page = computed(() => props.data)

      const onChange = (current: number, pageSize: number) => {
        context.emit('change', { page: current, perPage: pageSize })
      }
      const onShowSizeChange = (current: number, pageSize: number) => {
        context.emit('change', { page: 1, perPage: pageSize })
      }
      return {
        page,
        onChange,
        onShowSizeChange,
      }
    },
  })

import { createLogger, createStore } from 'vuex'
import auth from './modules/auth'
import loading from './modules/loading'

const debug = process.env.NODE_ENV !== 'production'

export interface StateType {
  auth: { user: object; permissions: object }
  loading: { loading: object }
}

export default createStore({
  modules: {
    auth,
    loading,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : [],
})


  import { defineComponent, onMounted, provide, watchEffect } from 'vue'
  import MyLoading from '@/common/components/MyLoading/index.vue'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { MyLoading },
    provide: {
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
        submitWrapperCol: { span: 16, offset: 4 },
      },
      tagtypecolor: { 目录: 'success', 页面: 'processing', 按钮: 'warning' },
      tagColor: {
        进行中: 'cyan',
        已完成: 'blue',
        已取消: 'red',
        原图: '#5A5AAD',
        改图: '#FF79BC',
      },
    },
    setup() {
      const store = useStore()
      watchEffect(() => {
        provide('user', store.state.auth.user)
        console.log(store.state.auth.user, 'skk')
      })

      onMounted(() => {
        store.dispatch('auth/meAction')
      })
    },
  })

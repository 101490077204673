
  import { defineComponent, computed, ref } from 'vue'
  import { UserOutlined, DownOutlined } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import UpdateModal from '../modal/UpdateModal.vue'
  export default defineComponent({
    components: { UserOutlined, DownOutlined, UpdateModal },
    setup() {
      const store = useStore()
      const router = useRouter()
      const onLogout = () => {
        store.dispatch('auth/logoutAction')
        router.replace('/login')
      }
      const user = computed(() => store.state.auth.user)
      const onClickFun = (e: {}) => {
        modal.openModal('UpdateModal', '信息编辑', user)
        console.log(e, 'skk')
      }
      const modal = {
        name: ref('UpdateModal'),
        title: ref(''),
        data: ref({}),
        show: ref(false),
        onCancel: () => {
          //关闭
          modal.show.value = false
        },
        openModal: (name: string, title: string, data: {}) => {
          modal.name.value = name
          modal.title.value = title
          modal.data.value = data
          modal.show.value = true
        },
        onFinish: () => {
          //关闭/添加成功
          modal.show.value = false
        },
      }
      return {
        user,
        onClickFun,
        onLogout,
        modal,
      }
    },
  })


  import { defineComponent, ref, watchEffect } from 'vue'

  export interface TagItemType {
    color: string
    label: string
    value: string
  }
  export default defineComponent({
    props: {
      items: Array,
      value: String,
    },
    setup(props) {
      const tagColor = ref('')
      const labelTxt = ref('')
      watchEffect(() => {
        props.items?.map((item) => {
          const { color, value, label } = item as TagItemType
          if (value == props.value) {
            tagColor.value = color
            labelTxt.value = label
          }
        })
      })
      return {
        tagColor,
        labelTxt,
      }
    },
  })

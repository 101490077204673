import MyPagination from '@/common/components/MyPagination/index.vue'
import MyModal from '@/common/components/MyModal/index.vue'
import MyIcon from '@/common/components/MyIcon/index.vue'
import { App } from 'vue'
import {
  MyCreateButton,
  MyEditButton,
  MyDeleteButton,
  MyQueryButton,
} from '@/common/components/MyButtons/index'
import MyTag from '@/common/components/MyTag/index.vue'

export const setupCommon = (app: App<Element>) => {
  // MyPagination
  app.component('my-pagination', MyPagination)
  app.component('my-modal', MyModal)
  app.component('my-icon', MyIcon)
  app.component('my-create-button', MyCreateButton)
  app.component('my-edit-button', MyEditButton)
  app.component('my-delete-button', MyDeleteButton)
  app.component('my-query-button', MyQueryButton)
  app.component('my-tag', MyTag)
}

<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item
      label="登录账户："
      name="username"
      v-bind="validateInfos['username']"
    >
      <a-input
        type="text"
        placeholder="账户名"
        v-model:value="model.username"
      />
    </a-form-item>
    <a-form-item
      label="真实姓名："
      name="realname"
      v-bind="validateInfos['realname']"
    >
      <a-input type="text" placeholder="姓名" v-model:value="model.realname" />
    </a-form-item>
    <a-form-item
      label="姓名拼音："
      name="pinyin"
      v-bind="validateInfos['pinyin']"
    >
      <a-input type="text" placeholder="拼音" v-model:value="model.pinyin" />
    </a-form-item>
    <a-form-item label="手机号：" name="phone" v-bind="validateInfos['phone']">
      <a-input v-model:value="model.phone" placeholder="手机号" />
    </a-form-item>
    <a-form-item label="邮箱：" name="email" v-bind="validateInfos['email']">
      <a-input placeholder="邮箱" v-model:value="model.email" />
    </a-form-item>
    <a-form-item label="密码:">
      <a-input placeholder="不填不改" v-model:value="model.password" />
    </a-form-item>
    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { reactive, onMounted } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import moment from 'moment'
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    props: {
      data: Object,
    },
    setup(props, context) {
      const modelRef = reactive({
        //表单
        id: undefined,
        username: undefined, //用户名
        password: undefined, //密码
        realname: undefined, //真实姓名
        pinyin: undefined, //真实姓名拼音
        phone: undefined, //手机号
        email: undefined, //邮箱
      })
      const rulesRef = reactive({
        //校验
        username: [
          {
            required: true,
            type: 'string',
            message: '请设置登录账户！',
            trigger: 'change',
          },
        ],
        realname: [
          {
            required: true,
            type: 'string',
            message: '请输入真实姓名！',
            trigger: 'change',
          },
        ],
        pinyin: [
          {
            required: true,
            type: 'string',
            message: '请设置姓名拼音！',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            type: 'string',
            message: '请设置手机号！',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            type: 'string',
            message: '请设置邮箱！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = () => {
        requests({
          url: API.Auth.UpdateMe,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '编辑成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }
      const onchangeTime = (dates, dateStrings) => {
        modelRef.created_date = dateStrings
      }
      onMounted(() => {
        const data = props.data.value.value
        modelRef.id = data.id
        modelRef.username = data.username
        modelRef.realname = data.realname
        modelRef.pinyin = data.pinyin
        modelRef.phone = data.phone
        modelRef.email = data.email
        console.log(data, 'kk')
      })
      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
        onchangeTime,
      }
    },
  }
</script>

import { renderSlot as _renderSlot, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createVNode(_component_a_modal, {
      title: _ctx.props.title,
      visible: _ctx.visible,
      footer: null,
      width: _ctx.props.width,
      maskClosable: false,
      keyboard: false,
      destroyOnClose: "",
      onCancel: _ctx.onCancel
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          _renderSlot(_ctx.$slots, "default")
        ], 1024))
      ]),
      _: 3
    }, 8, ["title", "visible", "width", "onCancel"])
  ]))
}